import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import Login from './screens/Login/Login';
import NotFound from './screens/NotFound/NotFound';

export const App = () => (
	<Switch>
		<Redirect exact from="/" to="/login" />
		<Route path="/login" component={Login} />
		<Route path="*" component={NotFound} />
	</Switch>
);

export default withRouter( App );
