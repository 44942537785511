import React from 'react';
import { TextInput as TextInputCarbon } from 'carbon-components-react';
import PropTypes from 'prop-types';

import './TextInput.scss';

const TextInput = ( {
	onChange,
	type,
	placeholder,
	name,
	disabled,
	required,
	light,
	className,
	helperText,
	labelText,
	id
} ) => (
	<div className="TextInput">
		<TextInputCarbon
			id={id}
			name={name}
			type={type}
			disabled={disabled}
			onChange={event => onChange( event.target.values )}
			light={light}
			helperText={!required ? helperText : `${helperText} *`}
			labelText={labelText}
			className={className}
			placeholder={placeholder}
			required={required}
		/>
	</div>
);

TextInput.propTypes = {
	disabled: PropTypes.bool,
	light: PropTypes.bool,
	required: PropTypes.bool,
	onChange: PropTypes.func,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	helperText: PropTypes.string,
	id: PropTypes.string,
	labelText: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string
};

TextInput.defaultProps = {
	disabled: false,
	light: false,
	required: false,
	onChange: () => {},
	type: 'text',
	placeholder: '',
	name: undefined,
	className: '',
	helperText: '',
	id: '',
	labelText: ''
};

export default TextInput;
