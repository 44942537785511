import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import loggerMiddleware from './middlewares/logger';
import createRouteReducer from '../reducers';

export const history = createBrowserHistory();

const middlewares = [
	routerMiddleware( history ),
	thunk,
	reduxPackMiddleware
];

if ( process.env.NODE_ENV === 'development' ) {
	middlewares.push( loggerMiddleware );
}

export const store = createStore(
	createRouteReducer( history ),
	applyMiddleware( ...middlewares )
);
