import React from 'react';
import Button from '../components/Button/Button';
import TextInput from '../components/TextInput/TextInput';

import './LoginForm.scss';

function LoginForm() {
	return (
		<form className="LoginForm">
			<TextInput
				helperText="EMAIL"
				placeholder="Tapez votre email"
				light
				required
				type="email"
			/>
			<TextInput
				type="password"
				helperText="PASSE"
				placeholder="Tapez votre mot de passe"
				required
				light
			/>
			<Button>
				Connexion
			</Button>
		</form>
	);
}

export default LoginForm;
