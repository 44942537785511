import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonCarbon } from 'carbon-components-react';

import './Button.scss';

const Button = ( {
	children,
	name,
	disabled,
	className,
	onClick
} ) => (
	<div className="Button">
		<ButtonCarbon
			name={name}
			disabled={disabled}
			className={className}
			onClick={onClick}
		>
			{children}
		</ButtonCarbon>
	</div>
);

Button.propTypes = {
	children: PropTypes.node.isRequired,
	name: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	onClick: PropTypes.func
};

Button.defaultProps = {
	disabled: false,
	className: '',
	name: undefined,
	onClick: () => {}
};

export default Button;
