import React from 'react';
import AuthenticationLayout from '../../layouts/AuthenticationLayout/AuthenticationLayout';
import LoginForm from '../../forms/LoginForm';

import './Login.scss';

function Login() {
	return (
		<AuthenticationLayout>
			<div className="Login">
				<div className="login-card">
					<div className="inner-login-card">
						<div className="title">
							Bienvenue sur le Dashboard PRO
						</div>
						<div className="subtitle">
							Connectez-vous à votre compte
						</div>
						<LoginForm />
						<a href="/forgot-password" className="forgot-password">
							Mot de passe oubilé?
						</a>
					</div>
				</div>
			</div>
		</AuthenticationLayout>
	);
}

export default Login;
