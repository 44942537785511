import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faGlobe } from '@fortawesome/free-solid-svg-icons';

import './AuthenticationLayout.scss';

const AuthenticationLayout = ( { children } ) => (
	<div className="AuthenticationLayout">
		<div className="navbar">
			<div className="brand-name">
				<span>Big</span>
				<span>Five</span>
			</div>
			<div className="button-container">
				<FontAwesomeIcon icon={faQuestionCircle} aria-label="help" />
				<FontAwesomeIcon icon={faGlobe} aria-label="change language" />
			</div>
		</div>
		<div className="content">
			{children}
		</div>
		<div className="footer">
			<p>
				<a href="https://bigfivesolutions.com">BigFive</a>
				{' '}
				© 2018 BigFive Edition SAS
			</p>
		</div>
	</div>
);

AuthenticationLayout.propTypes = {
	children: PropTypes.node
};

AuthenticationLayout.defaultProps = {
	children: undefined
};

export default AuthenticationLayout;
