import React from 'react';
import { useHistory } from 'react-router-dom';
import AuthenticationLayout from '../../layouts/AuthenticationLayout/AuthenticationLayout';
import Button from '../../components/Button/Button';

import './NotFound.scss';

function NotFound() {
	const history = useHistory();

	return (
		<AuthenticationLayout>
			<div className="NotFound">
				<h1>404</h1>
				<h2>Page non trouvée</h2>
				<div className="user-message">
					<p>
						Nous n&apos;avons pas pu trouver cette page.
					</p>
					<p>
						Essayez en accédant au lien ci-dessous.
					</p>
				</div>
				<Button
					onClick={() => history.push( '/' )}
				>
					Go to dashboard
				</Button>
			</div>
		</AuthenticationLayout>
	);
}

export default NotFound;
